<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-auto col-xl-3 col-lg-3 col-md-4 col-12">
        <div class="card">
          <!-- <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Categories</h5>
          </div> -->
          <div class="p-3 border-bottom">
            <div>
              <h5 class="font-size-15 mb-3">
                <a
                  href="javascript: void(0);"
                  class="text-purple d-block"
                  data-toggle="collapse"
                  v-b-toggle.filterprodductcolor-collapse
                >
                  Sort By
                  <i
                    class="mdi mdi-chevron-up float-end accor-down-icon"
                  ></i>
                </a>
              </h5>

              <b-collapse visible id="filterprodductcolor-collapse">
              <div class="sortBy px-2">
                <div class="custom-control custom-radio d-flex align-items-center mb-3">
                    <input
                      type="radio"
                      id="default"
                      name="sort"
                      v-model="sortBy"
                      value=""
                      class="custom-control-input"
                      @change="sortByQuery"
                    />
                    <label
                      class="custom-control-label p-0 m-0 lh-1 ms-2 pt-1 fw-normal text-muted"
                      for="default"
                    >
                      Default
                    </label>
                </div>
                <div class="custom-control custom-radio d-flex align-items-center mb-3" v-for="(value,index) in sortByType" :key="index">
                    <input
                      type="radio"
                      :id="value.value"
                      name="sort"
                      v-model="sortBy"
                      :value="value.value"
                      class="custom-control-input"
                      @change="sortByQuery"
                    />
                    <label
                      class="custom-control-label p-0 m-0 lh-1 ms-2 pt-1 fw-normal text-muted"
                      :for="value.value"
                    >
                      {{value.label}}
                    </label>
                </div>
              </div>
              </b-collapse>
            </div>
          </div>
          <div class="p-3 border-bottom">
            <div>
              <h5 class="font-size-15 mb-3">
                <a
                  href="javascript: void(0);"
                  class="text-purple d-block"
                  data-toggle="collapse"
                  v-b-toggle.category-collapse
                >
                  Categories
                  <i
                    class="mdi mdi-chevron-up float-end accor-down-icon"
                  ></i>
                </a>
              </h5>

              <b-collapse visible id="category-collapse">
                <div class="py-5 text-center text-muted" v-if="loading">
                  <div
                    class="spinner-border text-secondary my-2"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <br />
                  <div>Loading...</div>
                </div>
                <ul class="list-group mb-1" v-else>
                  <li class="list-item list-group-item" v-for="(value, index) in categoryList" :key="`catg_${index}`">
                   
                    <div role="button" :class="{'text-muted': !isCategoryActive(value.id), 'text-primary fw-medium': isCategoryActive(value.id)}"
                    @click="navigateToCategory(value)"> {{ value.category_name }}
                    </div>
                    <div v-if="value.sub_category.length" class="rounded mt-2">
                      <div class="border px-3 rounded py-2 mb-1 bg-light"
                          role="button"
                          :class="{'text-muted': !isCategoryActive(value2.id), 'text-primary fw-medium': isCategoryActive(value2.id)}"
                          @click="navigateToCategory(value2)"
                          v-for="(value2, index2) in value.sub_category"
                          :key="`sub_catg_${index2}`">
                        {{ value2.category_name }}
                      </div>
                    </div>
                  </li>

                </ul>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl col-lg col-md">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row mb-0">
                <div class="col-12 mb-2">
                  <span class="cursor-pointer text-muted" @click="sortBy='', $router.push({name:'nova-market'})">Nova Market</span> / 
                  <span class="cursor-pointer text-capitalize text-primary" v-if="currentRouteName">{{ currentRouteName.replaceAll('-',' ') }}</span>
                </div>
                <div class="col-md-12 mb-2">
                  <div class="d-flex mb-0 position-relative">
                    <div class="app-search2 position-relative w-100">
                      <input type="text" placeholder="Search Products" class="form-control" v-model.trim="searchKeyword" autocomplete="no" required="" @change="handlePageChange(1)" @keyup.enter="handlePageChange(1)"><span class="mdi mdi-magnify search-icony"></span>
                    </div>
                    <div class="ms-2"><button class="btn btn-primary" @click="handlePageChange(1)">Search</button></div>
                    <div class="ms-2"><button class="btn btn-light " @click="clear();handlePageChange(1)">Clear</button></div>
                  </div>
                </div>
                <div class="col-12">
                  <p class="mb-0" v-if="selectedTag">Tag By: <a href="javascript:void(0)" class="btn btn-sm btn-primary ms-1 font-size-13" @click="removeTag()"> {{selectedTag}} <i class="bi bi-x-circle ms-2"></i></a></p>
                </div>
              </div>
             

             

              <div class="row" v-if="!pageLoading && searchKeyword && !returnData.length">
                <div class="col-12 text-muted text-center mt-3" >
                  <div class="bg-light py-5 rounded-3">
                      <h5><i class="bi bi-shop-window text-muted display-6"></i></h5>
                      <div class="font-size-15 text-muted">No Products Found</div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="pageLoading">
                <div class="col-12 text-muted text-center py-5 mt-0" >
                  <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                </div>
              </div>
              <div class="row" v-else>
                
                <div
                  class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6"
                  v-for="(value, index) in returnData"
                  :key="index"
                >
                  <div class="product-box rounded-4">
                    <div class="product-img ">
                      <div class="product-ribbon badge bg-danger lh-1" v-if="value.status=='out_of_stock'">
                        Out Of Stock
                      </div>
                      <!-- <div class="product-wishlist">
                        <a href="#">
                          <i class="bi bi-heart"></i>
                        </a>
                      </div> -->
                      <img
                         @click="showPdDetail(value)"
                        v-if="value.images.length>0"
                        :src="value.images[0]"
                        alt
                        class="d-block"
                      />
                    </div>

                    <div class="text-start product-content p-3">

                      <p class="text-muted font-size-12 mb-0">{{value.sub_title}} </p>
                      <h5 class="mb-1 text-dark font-size-15 textwith2line" @click="showPdDetail(value)">
                      {{ value.title }}
                      </h5>

                      <!-- <div class="font-size-13 mt-2 d-flex align-items-center text-muted mb-2">
                        <span class="me-2">
                          Retail:
                        </span>
                        <del class="ms-auto" v-if="value.price">
                          {{currency}} {{convertCurrencyFormat(value.price,true)}}
                        </del>
                      </div> -->
                      <div class="border p-2 px-3 pb-1 rounded-pill text-center d-block align-items-center text-muted font-size-14 mb-2">
                        <span class="d-block text-center font-size-11">
                          Retail Price  <!-- Merchant Price -->
                        </span>
                        <span class="ms-auto text-center text-body font-size-12" v-if="value.price">
                          {{currency}} <strong class="fw-semibold font-size-15 lh-1 text-dark" v-if="value.price">{{convertCurrencyFormat(value.price,true)}}</strong>
                        </span>
                      </div>

                      <div role="button" class="py-2 font-size-14 fw-medium btn btn-primary custom-button-gadient text-center rounded-pill d-flex align-items-center" @click="showPdDetail(value)">
                        <span class="w-100">View Detail</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
              <div class="row" v-if="returnData.length">
                <div class="col mt-3">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        hide-ellipsis
                        @change="handlePageChange"
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalData"
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <productCartnFav ref="modalFunc2" :data="{accessUsername:accessUsername, assessToken:accessToken, updateData:updateData}" @callParentFunction="fromChildFunction"  @callParentFunction2="fromChildFunction2" @callParentFunction3="fromChildFunction3"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
//import DatePicker from "vue2-datepicker";
import productCartnFav from "@/views/pages/app/modal/productCartnFav";
/**
 * Page
 */
const PageTitle = "Nova Market"
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
   //DatePicker,
    productCartnFav
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      currency: appConfig.currency,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      pageLoading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      typeOfLetter:"",
      searchKeyword: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false,
      updateData:{},
      categoryList:[],
      selectedCategory:"",
      selectedTag:"",
      sortByType:[
        {
          label: "Best Sellers",
          value: "best_sellers",
        },
        {
          label: "Price: Low to High",
          value: "price_low_to_high",
        },
        {
          label: "Price: High to Low",
          value: "price_high_to_low",
        },
        {
          label: "Newest",
          value: "newest",
        },
        {
          label: "Top Rated",
          value: "top_rated",
        }
      ]
    };
  },
  middleware: "authentication",
  async mounted(){
    this.reload();
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    this.getCategory();
    this.sortByQuery();
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  computed: {
    currentRouteId() {
      return this.$route.params.id; // Use params if your route uses params
    },
    currentRouteName() {
      return this.$route.params.name; // Use params if your route uses params
    }
  },
  methods:{
      removeTag() {
        this.selectedTag = ''
        this.$router.push({
          name: 'nova-market'
        }).catch(() => {});
        this.selectedCategory = ''
        this.handlePageChange(1);
      },
      sortByQuery() {
        this.searchKeyword = this.$route.query.keyword || ''
        this.$router.replace({ query: { sortBy: this.sortBy, keyword: this.searchKeyword } }).catch(() => {});
        this.handlePageChange(1);
      },
      fromChildFunction:function(e){
        var data = {}
        data = {
          id: e.id,
          category_name: e.title,
        }
        this.navigateToCategory(data);
      },
      fromChildFunction2:function(e){
        this.sortBy =''
        this.$router.replace({
          name: 'nova-market',
          query:{
            tag: e.title
          }
        }).catch(() => {});
        this.selectedTag = e.title
        this.handlePageChange(1);
      },
      fromChildFunction3(){
        this.$refs.main.callVerticalandTriggerTopbar();
      },
      
      navigateToCategory(value) {
        this.sortBy =''
        this.selectedTag =''
        this.$router.replace({
          name: 'nova-market with categories',
          params: {
            id: value.id,
            name: value.category_name.replace(' ', '-').toLowerCase()
          }
        }).catch(() => {});
        this.selectedCategory = value.id
        this.handlePageChange(1);
      },
      isCategoryActive(categoryId) {
        return categoryId == this.currentRouteId
      },
      showPdDetail(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc2.showProductDetail(),100)
      },
      getCategory(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.WebsiteHost + 'controller/category/initCategoryListV2',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.categoryList = [];
              this.categoryList = resData.category_list;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        console.log(this.$route.query.keyword)
        this.$router.replace({ query: { sortBy: this.sortBy, keyword: this.searchKeyword } }).catch(() => {});
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.searchKeyword);
        //this.exportData(1)
      },
      edit(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc2.showEditModal("letterSupport"),100)
      },
      remove(value) {
        Swal.fire({
          icon: 'info',
          title: `Confirm Deletion?`,
          html: `<p> Are you sure you want to delete this ${value.subject} from ${value.applicant_info.company_name}?</p> <p class="mb-0"> This action is irreversible, so make sure you want to proceed.</p>`,
          confirmButtonColor: '#FA6541',
          iconColor: '#202360',
          showCancelButton: true,
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            bodyFormData.append("letterDbCode", value.letter_db_code);
            axios({
              method: "post",
              url: appConfig.APIHost + "controller/letterOfSupport/deleteLetterOfSupport",
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.returnData = []
                Swal.fire({
                  icon: 'success',
                  title: `Deleted Successfully`,
                  html: `The ${value.subject} from ${value.applicant_info.company_name} has been successfully deleted.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
                this.handlePageChange(1)
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.loading = false
              }
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.loading = false
              this.disabled = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }
        })
      },
      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.sortBy= ""
        this.typeOfLetter=""
        this.searchKeyword= ""
        this.selectedCategory = ""
        this.selectedTag = ""
        this.filterType="created_date"
        this.date_range = this.$refs.commonFunc.last30Days()
        this.$router.replace({
          name: 'nova-market'
        }).catch(() => {});
        // this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.disabled = true;
        this.pageLoading = true;
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("type", this.typeOfLetter);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("sortBy", this.sortBy);
        bodyFormData.append("filterBy", this.filterType);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        
        if ('tag' in this.$route.query) {
          if (this.$route.query.tag){
            this.selectedTag = this.$route.query.tag
          }
        }else{
         this.selectedTag=""
        }

        if (this.$route.params.id){
          this.selectedCategory = this.$route.params.id
        }else{
          this.selectedCategory = ""
        }

        axios({
            // method: "post",
            //url: `/api/letter_of_support.json`,
            //url: appConfig.APIHost + 'controller/letterOfSupport/getAllLetterOfSupportList',
            method: "get",
            url: `${appConfig.WebsiteHost}/controller/product/getAllProductListByPagination?category=${this.selectedCategory}&sort=${this.sortBy}&limit=${this.perPage}&page=${pages}&keyword=${keyword}&tag=${this.selectedTag}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total_products;
            var responseData = resData.products;
            
            if (resData.status == 200) {
              this.returnData = responseData
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.disabled = false;
            this.pageLoading = false;
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disabled = false;
            this.pageLoading = false;
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='markets')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "markets" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>